import { useCallback, useContext, useEffect, useRef } from 'react';
import type { CancelTokenSource } from 'axios';
import Axios from 'axios';
import * as api from '@api';
import type { WorkspaceGlobalSearchBarFilters } from '@/types';
import { useDebounceValue } from '@utils';
import {
  WorkspaceGlobalSearchBarDispatchContext, WorkspaceGlobalSearchBarQueryContext,
  WorkspaceGlobalSearchBarFetchContext, WorkspaceGlobalSearchBarFiltersContext,
} from './Context';

export default function Fetch({ children }: ChildrenProps) {
  const [dispatch] = useContext(WorkspaceGlobalSearchBarDispatchContext);
  const { query } = useContext(WorkspaceGlobalSearchBarQueryContext);
  const { activeFilters, setAggregationsFilters } = useContext(WorkspaceGlobalSearchBarFiltersContext);
  const cancelSourceRef = useRef<CancelTokenSource>();

  const debouncedValue = useDebounceValue<string>(query, 100);

  const fetch = useCallback(async (query: string, filters?: WorkspaceGlobalSearchBarFilters) => {
    if (cancelSourceRef.current) {
      cancelSourceRef.current.cancel();
    }

    cancelSourceRef.current = Axios.CancelToken.source();

    const result = await api.workspaces.globalSearch.bar({
      query,
      filters,
      cancelToken: cancelSourceRef.current.token,
    });

    if (result) {
      dispatch({ items: result.items });
      setAggregationsFilters(result.aggregations);
    }
  }, [dispatch, setAggregationsFilters]);

  useEffect(() => {
    if (!debouncedValue.length) return;
    fetch(debouncedValue, activeFilters);
  }, [debouncedValue, activeFilters, fetch]);

  return (
    <WorkspaceGlobalSearchBarFetchContext.Provider value={{ fetch }}>
      {children}
    </WorkspaceGlobalSearchBarFetchContext.Provider>
  );
}

export { Fetch };