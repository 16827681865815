import http from '@services/http';

export const downloadTaggingRollupReport = () => {
  return http.get<Blob>(`/admin/reports/usage/tagging`, {
    responseType: 'blob',
  });
};

export const downloadTaggingProjectsReport = () => {
  return http.get<Blob>(`/admin/reports/usage/tagging/projects`, {
    responseType: 'blob',
  });
};

export const downloadClientUsersCreatedReport = () => {
  return http.get<Blob>(`/admin/reports/usage/signups/clients`, {
    responseType: 'blob',
  });
};

export const downloadExpertSignupsReport = () => {
  return http.get<Blob>(`/admin/reports/usage/signups/experts`, {
    responseType: 'blob',
  });
};

export const downloadAIChatReport = () => {
  return http.get<Blob>(`/admin/reports/usage/ai-chat`, {
    responseType: 'blob',
  });
};

export const downloadClientPublishedTranscriptsReport = () => {
  return http.get<Blob>(`/admin/reports/usage/transcripts/client-published`, {
    responseType: 'blob',
  });
};
