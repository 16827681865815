import { useCallback, useEffect, useReducer } from 'react';
import { captureException } from '@sentry/react';
import { useAuthChangeEffect } from '@containers/AppReadyState';
import * as $ws from '@services/websocket';
import { ConferenceSocketContext } from './Context';
import type { Socket } from './interfaces';

export function ConferenceSocketContainer({ children }: ChildrenProps) {
  const [state, dispatch] = useReducer(socket, createInitialState());

  const initialize = useCallback(() => {
    if (state.initialized) return;

    $ws.conference.close();

    $ws.conference.socket.once('connect_error', err => {
      if ($ws.conference.socket.sendBuffer?.length > 0) {
        captureException(err);
      }
      dispatch({
        type: 'connect-error',
        error: err,
      });
    });

    $ws.conference.socket.once('connect', () => {
      dispatch({ type: 'init' });
    });

    $ws.conference.open();

    $ws.conference.socket.on('disconnect', reason => {
      if ($ws.conference.socket.active && reason != 'io client disconnect' && reason != 'io server disconnect') {
        dispatch({
          type: 'reconnecting',
        });
      }
    });

    $ws.conference.socket.io.on('reconnect', () => {
      dispatch({
        type: 'reconnected',
      });
    });
  }, [state.initialized]);

  const destroy = useCallback(() => {
    dispatch({ type: 'destroy' });
    $ws.conference.close();
  }, []);

  const close = useCallback(() => {
    destroy();
  }, [destroy]);

  useEffect(() => {
    return () => {
      destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAuthChangeEffect(auth => {
    if (!auth) {
      destroy();
    }
  });

  const value = {
    state,
    initialize,
    close,
    raw: $ws.conference.socket,
  };

  return (
    <ConferenceSocketContext.Provider value={value}>
      {children}
    </ConferenceSocketContext.Provider>
  );
}

function socket(state: Socket.State, action: Socket.Action): Socket.State {
  switch (action.type) {
    case 'connect-error': {
      return {
        ...state,
        initialized: false,
        latestError: action.error,
      };
    }
    case 'init': {
      return {
        ...state,
        initialized: true,
      };
    }
    case 'destroy': return createInitialState();
    case 'reconnecting':
    case 'reconnected': {
      return {
        ...state,
        reconnecting: action.type === 'reconnecting',
      };
    }
  }
}

function createInitialState(): Socket.State {
  return {
    initialized: false,
    reconnecting: null,
  };
}