import { useEffect } from 'react';
import { Redirect, generatePath } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { captureException } from '@sentry/react';
import * as $conferences from '@api/conferences';
import { useAppReadyState } from '@containers/AppReadyState';
import { useConferenceParam, useSocket } from '@containers/Conference/hooks';
import { useSelectUser } from '@containers/Store';
import * as consts from '@consts';
import { getLocationFor } from '@utils';
import { ConferenceEntryAuthorized } from './ConferenceEntry.Authorized';
import { ConferenceEntryGuest } from './ConferenceEntry.Guest';
import styles from './style/ConferenceEntry.css';

export function ConferenceEntry() {
  const app = useAppReadyState();
  const user = useSelectUser();
  const socket = useSocket();

  const conferenceIdentifier = useConferenceParam();

  const { data, isError, isInitialLoading } = useQuery({
    queryKey: ['conference-entry', conferenceIdentifier, user?.id],
    queryFn: async () => {
      return $conferences.getConferenceEntry({
        conferenceIdentifier,
      });
    },
    enabled: !!conferenceIdentifier && !app.hydrating,
    refetchOnWindowFocus: false,
  });

  if (isInitialLoading || app.hydrating) {
    return null;
  }

  if (isError || !data) {
    if (!isError) captureException(`Entry query says no data even though its not an error`); //Capture this bad data case, it might be leading to bad entries

    return <Redirect to={generatePath(consts.path.Conference.NotFound, { conferenceIdentifier })} />;
  }

  if (!socket.state.initialized && socket.state.latestError) {
    return (
      <div className={styles.error}>
        <div>
          There was an error connecting to our servers. Websocket connections might be blocked by a corporate/hospital IT restriction.
        </div>
        <div>You can try connecting on a personal device or not using a corporate/hospital internet connection.</div>
        <div>Error: {socket.state.latestError.message}</div>
      </div>
    );
  }

  if (data.offPlatform) {
    const to = getLocationFor.call.root({ callId: data.call.id });
    return <Redirect to={to} />;
  }

  // support old links that use callIds
  if (data?.conferenceIdentifier && conferenceIdentifier !== data.conferenceIdentifier) {
    return <Redirect to={generatePath(consts.path.Conference.Entry, { conferenceIdentifier: data.conferenceIdentifier })} />;
  }

  if (!app.authenticated) {
    return (
      <ConferenceEntryGuest
        conferenceIdentifier={conferenceIdentifier} />
    );
  }

  if (!app.initialized) {
    return null;
  }

  if (data.requiresPin) {
    return (
      <ConferenceEntryGuest
        conferenceIdentifier={conferenceIdentifier}
        name={user.profile.fullname}
        isLoggedIn />
    );
  }

  return (
    <ConferenceEntryAuthorized
      conferenceIdentifier={conferenceIdentifier}
      name={user.profile.firstName} />
  );
}